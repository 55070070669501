import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import _axios from "../axios";
import buildLink, { path } from "../urls";
import { MdDoubleArrow } from "react-icons/md";
import { AccountContext } from "../contexts/AccountContext";

function DesktopMenu() {
  const [selectedTopCategory, setSelectedTopCategory] = useState({});
  const [menuCategories2, setMenuCategories2] = useState([]);
  const [selectedMenuCategory2, setSelectedMenuCategory2] = useState();
  const [viewMenuCategories2, setViewMenuCategories2] = useState(true);
  const [headerMenu, setHeaderMenu] = useState();
  const width = window.innerWidth;
  const [state, dispatch] = useContext(AccountContext);
  const [submenuPosition, setSubmenuPosition] = useState({ left: 0 });

  useEffect(() => {
    if (width > 650) {
      _axios
        .get(buildLink("all_categories", undefined, window.innerWidth))
        .then((response) => {
          try {
            const data = response.data.data;
            setSelectedTopCategory(data[0]);
          } catch (error) {}
        });
      _axios
        .get(buildLink("headerv2", undefined, window.innerWidth))
        .then((response) => {
          const data = response?.data;
          setMenuCategories2(data.data);
          setSelectedMenuCategory2(data[0]);
          setHeaderMenu(data.header_settings);
        });
    }
  }, []);

  useEffect(() => {
    if (state.viewProfileMenu) {
      setViewMenuCategories2(false);
    }
  }, [state.viewProfileMenu]);

  const handleMouseEnter = (category, event) => {
    const buttonRect = event.target.getBoundingClientRect();
    if (category["sub-categories-level1"]?.length > 0) {
      setSelectedMenuCategory2(category);
      setViewMenuCategories2(true);
      setSubmenuPosition({
        left: buttonRect.left + window.scrollX + buttonRect.width / 2,
      });
    }
  };
  return (
    <div className="hidden lg:block relative text-red-600">
      <nav className="header-menu border-b border-dgrey5 mt-6 h-17 xl:h-7.5 text-red-600">
        <div>
          <div className="flex flex-col xl:flex-row justify-center items-center mx-auto text-red-600">
            <ul className="navigation relative text-center flex justify-center">
              <div
                className="flex flex-row"
                onMouseLeave={() => setViewMenuCategories2(false)}
              >
                {menuCategories2?.length > 0 &&
                  menuCategories2.map((category) => (
                    <li
                      className="relative"
                      key={category["title"].mobile_type_id}
                    >
                      <Link
                        to={`${path}/${category["title"].title
                          .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                          .replace(/\s+/g, "-")
                          .replace(/'/g, "")}/c=${
                          category["title"].mobile_type_id
                        }`}
                        className={`${
                          viewMenuCategories2 &&
                          selectedMenuCategory2 === category
                            ? "bg-dgrey3 font-extrabold"
                            : ""
                        } ${
                          category["title"].mobile_type_id === 437
                            ? "text-dgreen"
                            : category["title"].mobile_type_id === 477
                            ? "text-red-600"
                            : ""
                        } bg-transparent text-dblack2 text-d15 p-2 font-semibold hover:font-extrabold hover:bg-dgrey3 uppercase whitespace-nowrap`}
                        dangerouslySetInnerHTML={{
                          __html: category["title"].title,
                        }}
                        onMouseEnter={(event) => {
                          if (
                            category["title"].mobile_type_id === 437 ||
                            category["title"].mobile_type_id === 477
                          ) {
                            setViewMenuCategories2(false);
                          } else {
                            handleMouseEnter(category, event);
                          }
                        }}
                      ></Link>
                    </li>
                  ))}
              </div>
            </ul>

            {/* Hot Sale Section - if needed */}
            {/* <div className="inline-block mt-4 xl:mt-0 ml-1">
          <Link
            to={`/August-Hot-Sale-/c=463`}
            className="bg-dred1 text-dwhite1 text-d12 p-2.5 whitespace-nowrap"
          >
            Hot Sale
          </Link>
        </div> */}
          </div>
        </div>
      </nav>

      <div
        className={`navigation-submenu absolute duration-500 shadow-md overflow-hidden bg-dgrey3 text-dblack1 z-50 text-left 
    w-[40%] top-full container ml-48`}
        style={{
          left: submenuPosition.left,
          transform: "translateX(-50%)",
        }}
        onMouseEnter={() => setViewMenuCategories2(true)}
        onMouseLeave={() => setViewMenuCategories2(false)}
      >
        {viewMenuCategories2 && selectedMenuCategory2 && (
          <div className="flex overflow-y-auto h-full">
            <div className="relative pt-7 bg-dgrey3 w-1/2">
              <div className="navigation-submenu-item left-3 top-7 pb-7 pr-2.5 pl-3.5 border-r border-dgrey5">
                <div className="text-lg font-bold mb-1.5">
                  <Link
                    to={`${path}/${selectedMenuCategory2["top-category"]?.name
                      ?.replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                      ?.replace(/\s+/g, "-")}/c=${
                      selectedMenuCategory2["top-category"].category_id
                    }`}
                    className="uppercase"
                    dangerouslySetInnerHTML={{
                      __html: selectedMenuCategory2["title"].title,
                    }}
                  ></Link>
                </div>
                <div className="inline-block">
                  {selectedMenuCategory2["sub-categories-level1"]?.map(
                    (subcategory) => (
                      <ul key={subcategory.category_id}>
                        <li>
                          <div className="flex pr-5">
                            <Link
                              to={`${path}/${subcategory.name
                                .replace(/\s+&amp;\s+|\s+&gt;\s+/g, "-")
                                .replace(/\s+/g, "-")}/c=${
                                subcategory.category_id
                              }`}
                              className="cursor-pointer hover:underline text-sm capitalize font-bold"
                              dangerouslySetInnerHTML={{
                                __html: subcategory.name,
                              }}
                            ></Link>
                          </div>
                        </li>
                      </ul>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="flex py-7 px-3 w-1/2 justify-start">
              <div className="dropdown-extra-content">
                {selectedMenuCategory2["partitions"]?.map(
                  (category) =>
                    category?.banners > 0 &&
                    category?.banners?.map((banner) => (
                      <Link
                        className="grid grid-flow-col"
                        key={banner.banner_id}
                      >
                        <img
                          src={`${
                            window.config["site-url"] +
                            "/image/" +
                            banner?.image
                          }`}
                          alt={selectedMenuCategory2["top-category"]?.name}
                        />
                      </Link>
                    ))
                )}
                {selectedMenuCategory2["top-category"].top_images &&
                  selectedMenuCategory2["top-category"]?.top_images?.map(
                    (image) => (
                      <div className="mx-5" key={image.src}>
                        <img
                          className="w-full"
                          src={`${
                            window.config["site-url"] + "/image/" + image?.src
                          }`}
                          alt={image.alt}
                        />
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DesktopMenu;
